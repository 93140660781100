import { useEffect, useRef, useState } from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import { PrimaryButton, TertiaryButton } from '../Layout';
import { ClassicEditor, toolbarItems2 } from '../TextContentEditor';
import { CONFIG } from '../../config';
import { CKEditor } from '@ckeditor/ckeditor5-react';

interface RichTextContentEditorProps {
  variant?: 'form-field' | 'confirm-buttons';
  text: string;
  onSave?: (editorText: string) => void;
  onCancel?: () => void;
  allowEdit?: boolean;
  placeholder?: string;
}

export default function RichTextContentEditor({
  variant = 'confirm-buttons',
  text,
  onSave,
  onCancel,
  allowEdit,
  placeholder = '',
}: RichTextContentEditorProps) {
  const [editor, setEditor] = useState<any>();
  let [data, setData] = useState(text);
  const [isEditing, setIsEditing] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setData(text);
  }, [text]);

  const handleReadOnlyState = (canEdit?: boolean) => {
    if (!editor) return;
    const toolbarElement = editor.ui.view.toolbar.element;
    if (!canEdit) {
      editor.enableReadOnlyMode('readOnly');
      toolbarElement.style.display = 'none';
      return;
    }
    editor.disableReadOnlyMode('readOnly');
    toolbarElement.style.display = 'flex';
  };

  useEffect(() => {
    handleReadOnlyState(allowEdit);
  }, [editor, allowEdit]);

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isEditing]);

  const onSaveClick = () => {
    setIsEditing(false);
    onSave && onSave(data);
  };

  const onCancelClick = () => {
    setIsEditing(false);
    if (text !== data) {
      setData(text);
    }
    onCancel && onCancel();
  };

  return (
    <Stack>
      <Stack className={variant === 'form-field' ? 'ck-as-form-field' : ''}>
        <CKEditor
          // @ts-ignore ClassicEditor is generated from webpack build, don't try to patch it
          editor={ClassicEditor}
          data={data}
          onReady={editor => {
            setEditor(editor);
          }}
          onFocus={(event, editor) => {
            if (allowEdit) {
              setIsEditing(true);
            }
          }}
          onChange={(event, editor) => {
            const html = editor.getData();
            setData(html);

            if (variant === 'form-field') {
              onSave && onSave(html);
            }
          }}
          config={{
            placeholder: allowEdit ? placeholder : '',
            licenseKey: CONFIG.REACT_APP_CKEDITOR_LICENSE_KEY,
            // Force removing MathType cause it makes a call to external
            // domain wiris.net and it's blocked in many banks
            removePlugins: [
              'TrackChanges',
              'Comments',
              'RevisionHistory',
              'RevisionTracker',
              'MathType',
            ],
            toolbar: toolbarItems2,
            // Needed otherwise MathType causes a config error on re-render
            mathTypeParameters: {
              editorParameters: { language: 'en' }, // MathType config, including language
            },
          }}
        />
        {variant === 'confirm-buttons' && (
          <HStack justifyContent={'end'} hidden={!isEditing}>
            <TertiaryButton
              size={'sm'}
              variant={'ghost'}
              onClick={onCancelClick}
            >
              Cancel
            </TertiaryButton>
            <PrimaryButton size={'sm'} onClick={onSaveClick}>
              Save
            </PrimaryButton>
          </HStack>
        )}
      </Stack>
    </Stack>
  );
}
