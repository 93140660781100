import { useContext, useEffect, useState } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags, EnableConfigFlag } from '../../../../hooks/useFlags';
import { Guideline } from '../../../../models/guideline';
import { useCurrentProject } from '../../../../hooks/useProjects';
import { AIGenerationConfig } from '../../../TextContentEditor/AITextContentEditor';
import { ModelDocumentTypeEnum } from '../../../../models/model_document';
import { MetadataContentEditor } from '../MetadataContentEditor';
import UsersContext from '../../../../contexts/UsersContext';

export default function RiskAssessmentNotes({
  guideline,
  readOnlyFields,
}: {
  guideline: Guideline;
  readOnlyFields: boolean;
}) {
  const { currentUser } = useContext(UsersContext);
  const { experimentSc3369RiskAssessmentGeneration } = useFlags();
  const { enabled: aiGenerationEnabled, config } =
    (experimentSc3369RiskAssessmentGeneration as EnableConfigFlag) || {
      enabled: false,
      config: {},
    };

  const { getAccessTokenSilently } = useAuth0();
  const { project } = useCurrentProject();
  const [aiGenerationConfig, setAIGenerationConfig] =
    useState<AIGenerationConfig>();

  useEffect(() => {
    (async () => {
      if (aiGenerationEnabled) {
        const accessToken = await getAccessTokenSilently();
        setAIGenerationConfig({
          initEvent: {
            eventName: 'generate-risk-assessment',
            args: {
              accessToken: accessToken,
              project_cuid: project?.cuid,
              content_id: guideline.content_id,
              config: config,
              user_cuid: currentUser?.cuid, // All args should be lowercase
            },
          },
          streamEvent: {
            eventName: `assessment-${guideline.cuid}`,
          },
        });
      }
    })();
  }, [aiGenerationEnabled, project, guideline, config]);

  const metadata = {
    content_id: guideline.content_id,
    content_type: ModelDocumentTypeEnum.validation_report,
  };

  return (
    <>
      <Box>
        <Heading size={'sm'} color={'inherit'} marginBottom={4}>
          Risk Assessment Notes
        </Heading>
        <MetadataContentEditor
          contents={metadata}
          readOnly={readOnlyFields}
          documentType={ModelDocumentTypeEnum.validation_report}
          customAIGenerationConfig={aiGenerationConfig}
        />
      </Box>
    </>
  );
}
