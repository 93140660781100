import {
  Text,
  Textarea,
  Input,
  VStack,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';
import { CustomFieldProps, CustomMultiLineTextProps } from '../types';
import { PrimaryButton } from '../../Layout';
import NullFieldDisplay from '../Generic/NullFieldDisplay';
import { CKEditorWrapper } from '../../TextContentEditor/CKEditorWrapper';
import { useMemo } from 'react';

const URIFieldDisplay = ({ value }: { value: string }) => {
  return (
    <VStack>
      <a href={value} target="_blank">
        <PrimaryButton>Go to URL</PrimaryButton>
      </a>
      <Text fontSize={'xs'}>{value}</Text>
    </VStack>
  );
};

const StringFieldDisplay = ({ value }: { value: string }) => {
  return <Text>{value}</Text>;
};

export default function CustomText({
  schema,
  mode,
  value,
  onChange,
}: CustomFieldProps) {
  const text = useMemo(() => {
    if (schema.type === 'multi-line') {
      const multiLineProps = schema.props as CustomMultiLineTextProps;
      if (multiLineProps.enableRichTextFormatting) {
        return value || multiLineProps.template;
      }
    }
    return value;
  }, [value, schema.type, schema.props]);

  if (mode === 'display' || mode === 'read-only') {
    if (!!text && schema.type === 'uri') {
      return <URIFieldDisplay value={text} />;
    }
    if (!!text) {
      if (schema.type === 'multi-line') {
        const multiLineProps = schema.props as CustomMultiLineTextProps;
        if (multiLineProps.enableRichTextFormatting) {
          return (
            <Box
              className="ck ck-content ck-content"
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          );
        }
      }
      return <StringFieldDisplay value={text} />;
    } else if (mode === 'read-only') {
      return <NullFieldDisplay />;
    } else {
      return null;
    }
  }

  if (schema.type === 'multi-line') {
    const multiLineProps = schema.props as CustomMultiLineTextProps;
    if (multiLineProps.enableRichTextFormatting) {
      return (
        <CKEditorWrapper
          text={text}
          onChange={onChange}
          readOnly={false}
          enabledFeatures={{
            images: false,
            comments: false,
            revisions: false,
            deleteBlock: false,
            generateWithAI: false,
          }}
          hideTooltip={true}
          showOutline={true}
          autoSave={false}
          withHeight={true}
        />
      );
    }

    return (
      <Textarea
        value={text}
        minHeight={36}
        focusBorderColor="brand.base"
        onChange={e => onChange(e.target.value)}
        bg={useColorModeValue('white', 'neutral.850')}
      />
    );
  }

  return (
    <Input
      type={'text'}
      value={text}
      focusBorderColor="brand.base"
      bg={useColorModeValue('white', 'neutral.850')}
      onChange={e => {
        onChange(e.target.value);
      }}
    />
  );
}
