import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from '../../hooks/useFlags';
import ValidMindLogo from '../../components/ValidMindLogo';
import {
  Center,
  GridItem,
  Grid,
  VStack,
  Button,
  Text,
  Heading,
  Box,
  Link,
} from '@chakra-ui/react';
import ProductShots from '../../components/ProductShots';
import AcceptInvitationButton from '../../components/AcceptInvitationButton';
import { Copyright } from '../../components/Copyright';

const HomePage = () => {
  const { loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { releaseSc3255SsoFlow } = useFlags();

  const onlyWhitelistedEmailsError =
    searchParams.get('error_description') === 'only_whitelisted_emails';
  const inviteCuid = searchParams.get('invite');

  if (useAuth0().isAuthenticated && !inviteCuid) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  return (
    <Grid
      templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(8, 1fr)' }}
      height={{ xl: '100vh' }}
      bg={'neutral.50'}
      color={'neutral.800'}
      overflow={'hidden'}
    >
      <GridItem colSpan={3}>
        <VStack height={'full'} justifyContent={'space-between'}>
          <Center height={'full'}>
            {onlyWhitelistedEmailsError ? (
              <VStack padding={16} alignItems={'self-start'} w={'full'}>
                <Box color={'brand.base'}>
                  <ValidMindLogo />
                </Box>
                <Box pt={8} pb={4}>
                  <Heading fontSize={'xl'} color={'neutral.600'}>
                    Thank you for registering!
                  </Heading>
                  <Text>
                    You're on the waitlist — stay tuned.
                    <br />
                    <br />
                    In the meantime,{' '}
                    <Link href="https://www.validmind.com">
                      sign up for updates from ValidMind
                    </Link>
                    , or{' '}
                    <Link href="https://www.validmind.com">
                      join our online community
                    </Link>
                    .
                  </Text>
                </Box>
                <Link href="https://www.validmind.com">
                  Go back to validmind.ai
                </Link>
              </VStack>
            ) : (
              <VStack
                padding={16}
                alignItems={'self-start'}
                w={'full'}
                color={'pink.600'}
              >
                <Heading fontSize={'xl'} color={'neutral.600'}>
                  Welcome to
                </Heading>
                <ValidMindLogo />
                <Box pt={8} pb={4}>
                  <Text maxWidth={'prose'} color={'neutral.800'}>
                    The most efficient solution to automate model testing,
                    documentation, and risk management for AI/ML, LLM, and
                    statistical models.
                  </Text>
                  <VStack alignItems={'flex-start'} pt={8}>
                    {inviteCuid ? (
                      <AcceptInvitationButton inviteCuid={inviteCuid} />
                    ) : (
                      <>
                        <Button
                          bg={'pink.600 !important'}
                          color={'neutral.50 !important'}
                          _hover={{
                            bg: 'pink.500 !important',
                            // color: 'pink.700 !important',
                          }}
                          onClick={() => loginWithRedirect()}
                          w={'full'}
                        >
                          Sign in to ValidMind
                        </Button>
                        {releaseSc3255SsoFlow ? (
                          <Center w={'full'}>
                            <Link onClick={() => navigate('/sso')}>
                              Sign in with SSO
                            </Link>
                          </Center>
                        ) : null}
                      </>
                    )}
                  </VStack>
                </Box>
              </VStack>
            )}
          </Center>
          <Box w={'full'} color={'neutral.800'}>
            <Center>
              <Copyright mt={0} mb={4} />
            </Center>
          </Box>
        </VStack>
      </GridItem>
      <GridItem
        colSpan={5}
        bg={
          'radial-gradient(90.44% 90.44% at 0% 43.78%, #DF2780 0%, #8A144D 100%)'
        }
        scrollBehavior={'smooth'}
        pos={'relative'}
      >
        <ProductShots />
      </GridItem>
    </Grid>
  );
};

export default HomePage;
