import { useEffect, useState } from 'react';
import API from '../../api/API';
import { TSeverity } from '../../models/finding';
import { TModelInventoryFilters } from '../../models/inventory_model';
import { TValidMindBarDef, ValidmindBarChart } from '../ValidmindBarChart';
import {
  Box,
  CircularProgress,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { EmptyStateDisplay } from '../EmptyStateDisplay';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { LoadingContainer } from '../LoadingContainer';

type TChartTypes = 'bar';

type TBarCharts = {
  [key: string]: {
    title: string;
    category: 'findings' | 'models';
    getChartData: (context: TChartDataContext) => Promise<TBarChartData>;
    yAxisLabel?: string;
    showLegendValue?: boolean;
  };
};

export const BarCharts: TBarCharts = {
  'findings-by-risk-sev': {
    title: 'Findings by Risk Area',
    category: 'findings',
    getChartData: async ({ getAccessTokenSilently, severities, navigate }) => {
      const accessToken = await getAccessTokenSilently();
      const summary = await API.GetAnalytics(
        accessToken,
        'findings',
        ['report_risk_name', 'report_severity'],
        ['risk_area_cuid'],
      );

      return {
        colNameKey: 'report_risk_name',
        bars: severities.map(severity => {
          return {
            dataKey: severity.name,
            fill: severity.colors.primary,
            stackId: 'stack',
            onClick: data => {
              const stringify = encodeURIComponent(
                JSON.stringify({
                  risk_areas: [data.filter.risk_area_cuid],
                }),
              );
              navigate(`/model-findings?filters=${stringify}`);
            },
          };
        }),
        data: summary.data,
      };
    },
  },
  'findings-by-status-sev': {
    title: 'Findings by Status',
    category: 'findings',
    getChartData: async ({ getAccessTokenSilently, severities, navigate }) => {
      const accessToken = await getAccessTokenSilently();
      const summary = await API.GetAnalytics(
        accessToken,
        'findings',
        ['report_status', 'report_severity'],
        ['report_status'],
      );

      return {
        colNameKey: 'report_status',
        bars: severities.map(severity => {
          return {
            dataKey: severity.name,
            fill: severity.colors.primary,
            stackId: 'stack',
            onClick: data => {
              const stringify = encodeURIComponent(
                JSON.stringify({
                  status: [data.filter.report_status],
                }),
              );
              navigate(`/model-findings?filters=${stringify}`);
            },
          };
        }),
        data: summary.data,
      };
    },
  },
  'findings-by-bu-sev': {
    title: 'Findings by Business Unit',
    category: 'findings',
    getChartData: async ({ getAccessTokenSilently, severities, navigate }) => {
      const accessToken = await getAccessTokenSilently();
      const summary = await API.GetAnalytics(
        accessToken,
        'findings',
        ['report_business_unit', 'report_severity'],
        ['business_unit_cuid'],
      );

      return {
        colNameKey: 'report_business_unit',
        bars: severities.map(severity => {
          return {
            dataKey: severity.name,
            fill: severity.colors.primary,
            stackId: 'stack',
            onClick: data => {
              const stringify = encodeURIComponent(
                JSON.stringify({
                  business_units: [data.filter.business_unit_cuid],
                }),
              );
              navigate(`/model-findings?filters=${stringify}`);
            },
          };
        }),
        data: summary.data,
      };
    },
  },
  'findings-by-model-sev': {
    title: 'Findings by Model',
    category: 'findings',
    getChartData: async ({ getAccessTokenSilently, severities, navigate }) => {
      const accessToken = await getAccessTokenSilently();
      const summary = await API.GetAnalytics(
        accessToken,
        'findings',
        ['report_model_name', 'report_severity'],
        ['inventory_model_cuid'],
      );

      return {
        colNameKey: 'report_model_name',
        bars: severities.map(severity => {
          return {
            dataKey: severity.name,
            fill: severity.colors.primary,
            stackId: 'stack',
            onClick: data => {
              const stringify = encodeURIComponent(
                JSON.stringify({
                  inventory_models: [data.filter.inventory_model_cuid],
                }),
              );
              navigate(`/model-findings?filters=${stringify}`);
            },
          };
        }),
        data: summary.data,
      };
    },
  },
  'models-by-bu-tiering': {
    title: 'Number of Models by Business Unit and Tier',
    category: 'models',
    getChartData: async ({
      getAccessTokenSilently,
      modelFilters,
      navigate,
    }) => {
      const accessToken = await getAccessTokenSilently();
      const summary = await API.GetAnalytics(
        accessToken,
        'models',
        ['report_business_unit', 'business_unit_cuid', 'report_model_tiering'],
        ['business_unit_cuid'],
      );

      return {
        colNameKey: 'report_business_unit',
        bars: TIERS.map(t => ({
          ...t,
          onClick: data => {
            const matchingBU = modelFilters?.business_units.filter(
              bu => bu.cuid === data.filter.business_unit_cuid,
            );
            const stringify = encodeURIComponent(
              JSON.stringify({
                business_units: matchingBU,
                tiering: [t.dataKey],
              }),
            );
            navigate(`/model-inventory?filters=${stringify}`);
          },
        })),
        data: summary.data,
      };
    },
  },
  'models-by-bu-status': {
    title: 'Number of Models by Status and Business Unit',
    category: 'models',
    getChartData: async ({
      getAccessTokenSilently,
      modelFilters,
      navigate,
    }) => {
      const accessToken = await getAccessTokenSilently();
      const summary = await API.GetAnalytics(
        accessToken,
        'models',
        ['report_model_status_name', 'status_id', 'report_business_unit'],
        ['status_id'],
      );

      const bars: TValidMindBarDef[] =
        modelFilters?.business_units.map((bu, index) => {
          return {
            dataKey: bu.name,
            fill: getColor(index),
            stackId: bu.name,
            onClick: data => {
              const matchingBU = modelFilters?.business_units.filter(bu =>
                Object.keys(data.payload).includes(bu.name),
              );
              const matchingStatus = modelFilters?.status.filter(
                status => status.id === data.filter.status_id,
              );
              const stringify = encodeURIComponent(
                JSON.stringify({
                  business_units: matchingBU,
                  status: matchingStatus,
                }),
              );
              navigate(`/model-inventory?filters=${stringify}`);
            },
          };
        }) || [];

      return {
        colNameKey: 'report_model_status_name',
        bars,
        data: summary.data,
      };
    },
  },
  'models-by-status-duration': {
    title: 'Avg. Number of Days Models Spend in a Status',
    yAxisLabel: 'Avg: Days in Status',
    category: 'models',
    getChartData: async ({
      getAccessTokenSilently,
      modelFilters,
      navigate,
    }) => {
      const accessToken = await getAccessTokenSilently();
      const data = await API.GetAnalyticsBUByStatusDuration(accessToken);

      const bars: TValidMindBarDef[] =
        modelFilters?.business_units.map((bu, index) => {
          return {
            dataKey: bu.name,
            fill: getColor(index),
            stackId: bu.name,
            onClick: data => {
              const matchingBU = modelFilters?.business_units.filter(bu =>
                Object.keys(data.payload).includes(bu.name),
              );
              const matchingStatus = modelFilters?.status.filter(
                status => status.name === data.status_id,
              );
              const stringify = encodeURIComponent(
                JSON.stringify({
                  business_units: matchingBU,
                  status: matchingStatus,
                }),
              );
              navigate(`/model-inventory?filters=${stringify}`);
            },
          };
        }) || [];

      return {
        colNameKey: 'status_name',
        bars,
        data: data,
      };
    },
  },
  // 'models-by-bu-findings': {
  //   title: 'Number of Models by Findings count and Business Unit',
  //   category: 'models',
  //   showLegendValue: false,
  //   getChartData: async ({
  //     getAccessTokenSilently,
  //     modelFilters,
  //     navigate,
  //   }) => {
  //     const accessToken = await getAccessTokenSilently();
  //     const summary = await API.GetAnalytics(
  //       accessToken,
  //       ['report_business_unit', 'report_severity'],
  //       ['report_business_unit'],
  //     );

  //     const bars: TValidMindBarDef[] =
  //       modelFilters?.business_units.map((bu, index) => {
  //         return {
  //           dataKey: bu.name,
  //           fill: getColor(index),
  //           stackId: 'stack',
  //           onClick: data => {
  //             const matchingBU = modelFilters?.business_units.filter(bu =>
  //               Object.keys(data.payload).includes(bu.name),
  //             );
  //             const stringify = encodeURIComponent(
  //               JSON.stringify({
  //                 business_units: matchingBU,
  //               }),
  //             );
  //             navigate(`/model-inventory?filters=${stringify}`);
  //           },
  //         };
  //       }) || [];

  //     // Since this chart is a bit different, we need to do some post-processing

  //     const totalMap: {
  //       [key: string]: {
  //         [key: string]: number;
  //       };
  //     } = {};

  //     summary.data.forEach(row => {
  //       const dataKeys = Object.keys(row).filter(
  //         key => key !== 'report_business_unit' && key !== 'filter',
  //       );
  //       const total = dataKeys.reduce((acc, key) => acc + row[key], 0);
  //       if (!totalMap[`${total}`]) {
  //         totalMap[`${total}`] = {};
  //       }
  //       totalMap[`${total}`][row.report_business_unit] =
  //         (totalMap[`${total}`][row.report_business_unit] || 0) + 1;
  //     });

  //     const data = [] as any[];

  //     Object.keys(totalMap).forEach(total => {
  //       const buKeys = Object.keys(totalMap[total]);
  //       const obj = {
  //         report_total: `${total} Finding${total === '1' ? '' : 's'}`,
  //       } as any;
  //       buKeys.forEach(bu => {
  //         obj[bu] = totalMap[total][bu];
  //       });
  //       data.push(obj);
  //     });

  //     return {
  //       colNameKey: 'report_total',
  //       bars: bars,
  //       data: data,
  //     };
  //   },
  // },
} as const;

// const BarChartKeys = [
//   'findings-by-risk-sev',
//   'findings-by-status-sev',
//   'findings-by-bu-sev',
//   'findings-by-model-sev',
//   'models-by-bu-tiering',
//   'models-by-bu-status',
//   'models-by-status-duration',
//   'models-by-bu-findings',
// ] as const;

type TBarChartData = {
  colNameKey: string;
  bars: TValidMindBarDef[];
  data: any[];
};

type TChartDataContext = {
  getAccessTokenSilently: () => Promise<string>;
  navigate: (path: string) => void;
  severities: TSeverity[];
  modelFilters?: TModelInventoryFilters;
};

const getColor = (index: number) => {
  const colors = [
    '#d64e34',
    '#693ad1',
    '#7de34d',
    '#c73edd',
    '#d0d24b',
    '#4a2a80',
    '#67b351',
    '#d157b9',
    '#6adaad',
    '#d24e6b',
    '#416d31',
    '#7879d8',
    '#d6923d',
    '#382340',
    '#cbd59e',
    '#8e3c74',
    '#8b7d36',
    '#cb9fcf',
    '#3a3e2a',
    '#86c4d8',
    '#7c392a',
    '#5c8b79',
    '#c79484',
    '#5a6d95',
  ];

  return colors[index % colors.length];
};

export const getChartTypeFromId = (id: string): TChartTypes => {
  if (Object.keys(BarCharts).includes(id)) {
    return 'bar';
  }
  return 'bar';
};

const TIERS = [
  {
    dataKey: '1',
    fill: '#000000',
    stackId: 'stack',
    name: 'Tier 1',
  },
  {
    dataKey: '2',
    fill: '#67748A',
    stackId: 'stack',
    name: 'Tier 2',
  },
  {
    dataKey: '3',
    fill: '#71A4F6',
    stackId: 'stack',
    name: 'Tier 3',
  },
  {
    dataKey: '4',
    fill: '#579E6C',
    stackId: 'stack',
    name: 'Tier 4',
  },
];

const BarChartWidget = ({
  chartId,
  chart,
  context,
}: {
  chartId: string;
  chart: TBarCharts[keyof TBarCharts];
  context: TChartDataContext;
}) => {
  const [isFetching, setIsFetching] = useState(true);
  const [chartData, setChartData] = useState<TBarChartData>();

  const { title, getChartData } = chart;

  useEffect(() => {
    (async () => {
      setIsFetching(true);
      const chartData = await getChartData(context);
      setChartData(chartData);
      setIsFetching(false);
    })();
  }, [getChartData]);

  if (isFetching) {
    return (
      <Flex justifyContent={'center'} height={'full'} width={'full'}>
        <Stack justify={'center'} align={'center'}>
          <CircularProgress
            size="40px"
            thickness="2px"
            isIndeterminate
            color="brand.base"
          />
          <Text fontSize={'xs'} color={'inherit'}>
            Please hold...
          </Text>
        </Stack>
      </Flex>
    );
  }

  const noData = !chartData?.data.length;

  return (
    <Box height="full" width="full" key={chartId}>
      {noData ? (
        <EmptyStateDisplay variant="no-reports">
          <Heading as={'h4'} fontSize={'md'}>
            No data
          </Heading>
          <Text align={'center'}>There are no {title} yet.</Text>
        </EmptyStateDisplay>
      ) : (
        <ValidmindBarChart
          id={chartId}
          data={chartData.data}
          barColumnName={chartData.colNameKey}
          bars={chartData.bars}
          showLegendValue={chart.showLegendValue}
          yAxisLabel={chart.yAxisLabel}
        />
      )}
    </Box>
  );
};

type ReportChartProps = {
  chartId: string;
};

export default function ReportChart({ chartId }: ReportChartProps) {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { data: severities = [], isFetching: isFetchingSeverities } = useQuery(
    ['findings', 'severities'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetFindingSeverities(accessToken);
    },
  );

  const { data: modelFilters, isFetching: isFetchingModelFilters } = useQuery(
    ['findings', 'businessUnits'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetModelInventoryFilters(accessToken);
    },
  );

  if (isFetchingSeverities || isFetchingModelFilters) {
    return (
      <LoadingContainer isLoading={true}>
        <Text>Loading</Text>
      </LoadingContainer>
    );
  }

  const chartType = getChartTypeFromId(chartId);

  if (chartType === 'bar') {
    return (
      <BarChartWidget
        chartId={chartId}
        chart={BarCharts[chartId]}
        context={{
          getAccessTokenSilently,
          severities,
          modelFilters,
          navigate,
        }}
      />
    );
  }

  return null;
}
