import { Box, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useProjects } from '../../hooks/useProjects';
import { LoadingContainer } from '../LoadingContainer';
import { Label } from '../Layout';

interface ModelsUsingThisTemplateProps {
  selectedTemplateId: string;
}
export default function ModelsUsingThisTemplate({
  selectedTemplateId,
}: ModelsUsingThisTemplateProps) {
  const {
    projectResults: { results: projectsWithTemplate = [] } = {},
    isLoading,
  } = useProjects({
    filters: { template_cuid: selectedTemplateId },
    enabled: !!selectedTemplateId,
  });

  return (
    <Box>
      <Label>MODELS USING THIS TEMPLATE</Label>
      <LoadingContainer isLoading={isLoading}>
        <Box h={'20rem'} overflow={'scroll'}>
          {projectsWithTemplate.length === 0 ? (
            <Text color="neutral.500" fontStyle={'italic'} fontSize={'sm'}>
              This template is not being used by any models.
            </Text>
          ) : (
            <UnorderedList
              fontSize={'sm'}
              spacing={2}
              lineHeight={'1.2rem'}
              marginInlineStart={2}
            >
              {projectsWithTemplate.map(p => (
                <ListItem key={p.cuid}>
                  <Link
                    as={RouterLink}
                    to={`/model-inventory/${p.inventory_model.cuid}`}
                  >
                    {/* {p.name} (Version X) */}
                    {p.name}
                  </Link>
                </ListItem>
              ))}
            </UnorderedList>
          )}
        </Box>
      </LoadingContainer>
    </Box>
  );
}
